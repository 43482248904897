<template>
  <div class="conOfChangePassPage fill-height">
    <v-form class="fill-height conOfLoginForm">
      <v-container fluid class="fill-height">
        <v-row class="fill-height d-flex justify-center">
          <v-col md="4" cols="12">
            <div class="formCard fill-height">
              <div class="conOfFormSection fill-height">
                <v-row>
                  <v-col cols="12" class="">
                    <div class="d-flex addThumbnailText">Add Image</div>
                    <input type="file" @change="showFilePreview" />
                    <div id="preview">
                      <img
                        v-if="image"
                        :src="image"
                        height="150px"
                        width="200px"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12 pb-2">
                    <label class="labelPass">Edit Name</label>

                    <v-text-field
                      outlined
                      class="formInput"
                      v-model="form.name"
                      placeholder="Enter Name"
                      required
                      :error-messages="NameErrors"
                      @input="$v.form.name.$touch()"
                      @blur="$v.form.name.$touch()"
                      solo
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <div class="conLoginFooter px-3">
                      <div class="btnLoginCon">
                        <v-btn
                          class="primaryBtn loginBtn"
                          text
                          tile
                          :disabled="isLoading"
                          :loading="isLoading"
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="notvalidMessage">
                    <div class="vaidMessage">
                      {{ msgSucces }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "editProfile",
  mixins: [validationMixin],
  validations: {
    form: {
      name: { required },
    },
  },
  data() {
    return {
      originalImg: "",
      resizedImg: "",
      image: null,
      isLoading: false,
      notvalidMessage: false,
      // email: "admin@admin.com",
      // password: "12345678",
      form: {
        name: "",
      },
    };
  },
  methods: {
    showFilePreview(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    // onButtonClick() {
    //   this.isSelecting = true;
    //   window.addEventListener(
    //     "focus",
    //     () => {
    //       this.isSelecting = false;
    //     },
    //     { once: true }
    //   );

    //   this.$refs.uploader.click();
    // },
    // onFileChanged(e) {
    //   this.selectedFile = e.target.files[0];

    //   // do something
    // },
  },
  computed: {
    ...mapGetters(["apiMessage"]),

    NameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Name is required.");
      return errors;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/abstracts/variables";

.conOfChangePassPage {
  .conOfLoginForm {
    .conBollutsImg {
      img {
        max-width: 300px;
        width: 75%;
      }
    }

    .formCard {
      // border-radius: 0;
      // border: 0;
      // padding: 2rem;

      .conOfFormSection {
        max-width: 400px;
        display: flex;
        align-items: center;
        margin: auto;

        .loginHeader {
          text-align: center;
          img {
            width: 80%;
          }
        }

        .formInput {
          color: #242021;

          .v-input__slot {
            box-shadow: none !important;
          }
          .labelPass {
            font-size: 1rem;
            font-weight: 600;
            color: #1f2026;
          }
          // fieldset {
          //   box-shadow: none !important;
          //   border: 1px solid #D4D4D4;
          //   border-radius: 35px;
          // }

          label.v-label.v-label--active {
            left: -4px !important;
          }

          .v-icon {
            color: #a0a0a0;

            &.primary--text {
              color: #a0a0a0;
            }
          }
        }
      }

      .forgetPass {
        text-align: right;

        a {
          text-decoration: none;
          color: #febf2c;
          font-size: 1rem;
        }
      }

      .btnLoginCon {
        button.primaryBtn.loginBtn.v-btn {
          min-width: 100%;
          background: #febf2c;
          min-height: 60px;
          border-radius: 10px;
          color: #000000;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    .vaidMessage {
      color: $color-primary;
      font-size: 0.8rem;
      text-align: center;
    }

    .v-messages__message,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      margin: 0;
    }

    .v-text-field .v-label {
      font-size: 1rem;
    }

    .isHaveAccount {
      // color: $color-text-gray125;
      font-size: 0.9rem;
      text-transform: capitalize;
    }
  }
}

// ::v-deep {
//   .conOfLoginForm .primary--text {
//     color: $color-text-primary !important;
//     caret-color: $color-text-primary !important;
//   }

//   .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
//     border: 2px solid #FEBF2C;
// }
// }
</style>
